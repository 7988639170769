import { apiPromisify } from '@/Lib';
import { ProductProposal, ProductProposalApiService } from '@/Services/api/methods/ProductsProposals';

export class ProductProposalService {
    private readonly apiProductProposalService: ProductProposalApiService;

    constructor(apiProductProposalService: ProductProposalApiService) {
        this.apiProductProposalService = apiProductProposalService;
    }

    public async approve(productProposalForApprove: ProductProposal, udi: number) {
        const isActualProductProposal = await this.isActualProductProposal(productProposalForApprove);

        if (!isActualProductProposal) {
            throw new Error('The product proposal has changed');
        }

        return this.apiProductProposalService.approve(productProposalForApprove.id, udi);
    }

    public async pend(productProposalForPend: ProductProposal) {
        const isActualProductProposal = await this.isActualProductProposal(productProposalForPend);

        if (!isActualProductProposal) {
            throw new Error('The product proposal has changed');
        }

        return this.apiProductProposalService.pend(productProposalForPend.id);
    }

    public async reject(productProposalToReject: ProductProposal, rejectReason: string) {
        const isActualProductProposal = await this.isActualProductProposal(productProposalToReject);

        if (!isActualProductProposal) {
            throw new Error('The product proposal has changed');
        }

        return this.apiProductProposalService.reject(productProposalToReject.id, rejectReason);
    }

    private async isActualProductProposal(currentProduct: ProductProposal): Promise<boolean> {
        const actualProductProposal = await apiPromisify(this.apiProductProposalService.getById(currentProduct.id));

        return currentProduct.updated_at === actualProductProposal.updated_at;
    }
}
