import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@.ui';
import { joinCollectionByProp } from '@/Lib';
import { Auth, LinkHelper, Tools, useLingualData, useLingualField } from '@/Services';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';
import { ProductProposalPermissionUtils } from '@/Services/ProductProposal';
import { LingualName } from '@/Types/api/language';
import { ProductProposalsStatusEnum } from '@/Types/ProductProposals';

import S from './styles.module.scss';
import { useProductManagerActions } from './useProductManagerActions';

interface ProductProposalsDetailViewProps {
    product: ProductProposal;
    updateProductProposal: () => void;
}

export const ProductProposalsDetailView: FC<ProductProposalsDetailViewProps> = ({ product, updateProductProposal }) => {
    const auth = Auth.Use();
    const navigator = useNavigate();

    const productName = useLingualField(product);

    const { openApproveModal, openPendModal, openRejectModal, modalActionContent } = useProductManagerActions({ updateProductProposal });

    const isSuperAdmin = auth?.user?.is_super;

    const createDescriptionData = (): LingualName => {
        const description = product?.description || {};

        if (isSuperAdmin) {
            return description;
        }

        return { ...Tools.object.filterNullable(description) };
    };

    const productDescription = useLingualData(createDescriptionData());

    const image = product.images?.[0] || null;

    const isProductPendingStatus = product.status === ProductProposalsStatusEnum.PENDING;

    const isAllowEditProductProposal = ProductProposalPermissionUtils.isCanEditProductProposal(product.status, auth?.user);

    const isAllowPendProductProposal = ProductProposalPermissionUtils.isCanPendProductProposal(product.status, auth?.user);

    return (
        <div className="entity-page">
            <h1 className={S.header}>
                {productName}

                {isSuperAdmin && (
                    <div className={S.actions__buttons}>
                        {isProductPendingStatus && (
                            <Button color="brown" onClick={() => openApproveModal(product)}>
                                Approve
                            </Button>
                        )}
                        {isProductPendingStatus && (
                            <Button color="brown" onClick={() => openRejectModal(product)}>
                                Reject
                            </Button>
                        )}
                    </div>
                )}

                {ProductProposalPermissionUtils.isAllowToUpdateProductProposal(auth?.user) && (
                    <div className={S.actions__buttons}>
                        {isAllowEditProductProposal && (
                            <Button color="brown" onClick={() => navigator(LinkHelper.productProposalsEdit(product.id))}>
                                Edit
                            </Button>
                        )}
                        {isAllowPendProductProposal && (
                            <Button color="brown" onClick={() => openPendModal(product)}>
                                Pend
                            </Button>
                        )}
                    </div>
                )}
            </h1>
            <div className="entity-page__container">
                <div>
                    <div className="entity-page__item">
                        <div className="bold">Name</div>
                        <div>{productName}</div>
                    </div>
                    <div className="entity-page__item">
                        <div className="bold">Created by</div>
                        <div>{product.created_by.name}</div>
                    </div>
                    <div className="entity-page__item">
                        <div className="bold">Last updated</div>
                        <div>{Tools.date.autoFormat(product.updated_at * 1000)}</div>
                    </div>
                </div>
                {image && <img src={image.url} alt={image.name} />}
            </div>
            <div className="entity-page__item">
                <div className="bold">Current status</div>
                <div>{product.status}</div>
            </div>
            {product.status === ProductProposalsStatusEnum.REJECTED && (
                <div className="entity-page__item">
                    <div className="bold">Reject reason</div>
                    <div>{product.reject_reason}</div>
                </div>
            )}
            <div className="entity-page__item">
                <div className="bold">Description</div>
                <div>{productDescription}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Brand</div>
                <div>{product.brand.name}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Type</div>
                <div>{product.type.name}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Age</div>
                <div>{joinCollectionByProp(product.ages, 'name', ',')}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Sex</div>
                <div>{joinCollectionByProp(product.sexes, 'name', ',')}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Strength</div>
                <div>{product.strength.name}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Occasion</div>
                <div>{joinCollectionByProp(product.occasions, 'name', ',')}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Ingredients</div>
                <div>{joinCollectionByProp(product.ingredients, 'name', ',')}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Characters</div>
                <div>{joinCollectionByProp(product.characters, 'name', ',')}</div>
            </div>
            <div className="entity-page__item">
                <div className="bold">Tags</div>
                <div>{joinCollectionByProp(product.tags, 'name', ',')}</div>
            </div>

            {modalActionContent}
        </div>
    );
};
