import { User } from '@/Services/Auth/types';
import { UserRoleEnum } from '@/Types/api/User';

export const isProductManagerRole = (user?: User): boolean => {
    if (!user) {
        return false;
    }

    return user.role?.key === UserRoleEnum.PRODUCT_MANAGER;
};
