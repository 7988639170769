import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Api } from '@.api';
import { ProductType } from '@.api/methods';
import { Layout } from '@.layout';
import { CreateProductForm } from '@/Components/Partials/Product';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';
import { PermissionProps } from '@/Types';

type ProductProposalsCreateProps = {
    edit?: boolean;
} & PermissionProps;

const convertProductProposalsToProductType = (productProposal: ProductProposal): ProductType => {
    return {
        name: productProposal.name,
        sexes: productProposal.sexes,
        ages: productProposal.ages,
        characters: productProposal.characters,
        ingredients: productProposal.ingredients,
        occasions: productProposal.occasions,
        tags: productProposal.tags,
        type: productProposal.type,
        strength: productProposal.strength,
        images: productProposal.images,
        brand: productProposal.brand,
        id: productProposal.id,
        created_at: productProposal.created_at,
        description: productProposal.description,
        udi: 0,
        company_description: {},
        companies: [],
        deleted_at: 0,
        sales_count: 0,
        whiffs_count: 0,
    };
};

export const ProductProposalsCreate: FC<ProductProposalsCreateProps> = ({ permissions, edit }) => {
    const [product, setProduct] = useState<ProductProposal | undefined>(undefined);

    const { id } = useParams();

    const numberId = Number(id);

    useEffect(() => {
        if (edit && numberId) {
            Api.productsProposals()
                .getById(numberId)
                .onSuccess((response) => setProduct(response.data));
        }
    }, [edit, numberId]);

    const initialProductState = product ? convertProductProposalsToProductType(product) : undefined;

    return (
        <Layout name="ProductProposalsCreate" small>
            <CreateProductForm id={numberId} permissions={permissions} isFormForManagerRole={true} edit={edit} initialProductState={initialProductState} />
        </Layout>
    );
};
