import cn from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { ProductType } from '@.api/methods';
import { requiredRule, useDictionaries, useForm, ValidateOptions } from '@.hooks';
import { Input, LingualInput, Select, Upload } from '@.ui';
import { Buttons } from '@/Components/Partials';
import { Auth, getLingualErrors, LinkHelper, Tools } from '@/Services';
import { DictionaryList } from '@/Types';

import { CreateProductFormProps, ProductData } from './types';
import { validateOptions } from './validate';

type AdminFieldProps = { isAllowed?: boolean; children?: ReactNode; hidden?: boolean };

const AdminFields: FC<AdminFieldProps> = ({ isAllowed, children, hidden }) => {
    const isDisabled = !isAllowed;

    const adminFieldsClassName = cn('action-form', isDisabled && 'disabled', hidden && 'hidden');

    return <div className={adminFieldsClassName}>{children}</div>;
};

/**
 * Form for create product as admin or product manager.
 *
 * TODO: in future decompose and refactor again.
 */
export const CreateProductForm: FC<CreateProductFormProps> = ({ id, edit, permissions, initialProductState, isFormForManagerRole = false }) => {
    const auth = Auth.Use();

    const isUDIRequired = !isFormForManagerRole;

    const [product, setProduct] = useState<ProductType | undefined>(initialProductState);
    const [dictionaries, setDictionaries] = useDictionaries();
    const [field, form] = useForm<ProductData>();

    const navigator = useNavigate();

    const handleCreate = () => {
        form.ifValid((data) => {
            const dataPayload = {
                ...data,
                ages_ids: data.ages_ids ? data.ages_ids : [],
                tags_ids: data.tags_ids ? data.tags_ids : [],
            };

            const apiCreateMethod = () => (isFormForManagerRole ? Api.productsProposals().create(dataPayload) : Api.products().create(dataPayload));

            apiCreateMethod()
                .onSuccess((response) => {
                    if (isFormForManagerRole) {
                        navigator(LinkHelper.productProposalsDetail(response.data.id));
                        return;
                    }
                    navigator(LinkHelper.dataProducts);
                })
                .onValidationError((res) =>
                    form.set.errors({
                        ...res.errors,
                        name: getLingualErrors(res.errors, 'name'),
                    })
                );
        }, validateOptions(isUDIRequired));
    };

    const handleUpdate = () => {
        if (!id) {
            return;
        }

        if (auth?.user?.is_super || isFormForManagerRole) {
            form.ifValid((data) => {
                const dataPayload = {
                    ...data,
                    ages_ids: data.ages_ids ? data.ages_ids : [],
                    tags_ids: data.tags_ids ? data.tags_ids : [],
                };

                const updateApiMethod = () => (isFormForManagerRole ? Api.productsProposals().update(dataPayload, id) : Api.products().update(dataPayload, id));

                updateApiMethod()
                    .onSuccess((response) => {
                        if (isFormForManagerRole) {
                            navigator(LinkHelper.productProposalsDetail(response.data.id));
                            return;
                        }

                        navigator(LinkHelper.dataProducts);
                    })
                    .onValidationError((res) =>
                        form.set.errors({
                            ...res.errors,
                            name: getLingualErrors(res.errors, 'name'),
                        })
                    );
            }, validateOptions(isUDIRequired));

            return;
        }

        if (auth?.user?.permissions?.product_descriptions) {
            form.ifValid(
                (data) => {
                    const { description } = data;

                    if (!description) {
                        return;
                    }

                    const lingualData = Object.fromEntries(Object.entries(description).map(([lang, value]) => [`description_${lang}`, value]));

                    Api.products()
                        .description(Number(id), lingualData)
                        .onSuccess(() => navigator(LinkHelper.dataProducts))
                        .onValidationError((res) => {
                            form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') });
                        });
                },
                {
                    description: {
                        type: 'lingual',
                        required: requiredRule,
                        max: {
                            value: 1024,
                            message: 'Field must be up to 1024 characters',
                        },
                    },
                } as ValidateOptions<ProductData>
            );
        }
    };

    useEffect(() => {
        setProduct(initialProductState);
    }, [initialProductState]);

    useEffect(() => {
        if (edit && product) {
            const { udi, name, description, company_description, brand, sexes, ages, characters, ingredients, occasions, tags, type, strength, images } =
                product;

            const getDescription = () => {
                if (auth?.user?.is_super) return description;

                if (!company_description) return description;

                return { ...Tools.object.filterNullable(description ?? {}), ...Tools.object.filterNullable(company_description) };
            };

            const pickId = (collection: Array<{ id: number }>): Array<number> => {
                return collection.map(({ id }) => id);
            };

            form.set.values({
                udi,
                name,
                description: getDescription(),
                brand_id: brand.id,
                product_type_id: type.id,
                strength_id: strength.id,
                sexes_ids: pickId(sexes),
                ages_ids: pickId(ages),
                tags_ids: pickId(tags),
                images_ids: pickId(images),
                occasions_ids: pickId(occasions),
                characters_ids: pickId(characters),
                ingredients_ids: pickId(ingredients),
            });
        }
    }, [product, edit, auth?.user?.is_super]);

    useEffect(() => {
        const dictionaries: DictionaryList = [
            'StoreGroups',
            'Brands',
            'ProductTypes',
            'Ages',
            'Sexes',
            'Strengths',
            'Occasions',
            'Ingredients',
            'Characters',
            'Tags',
        ];

        setDictionaries(dictionaries);
    }, []);

    return (
        <div className="action-form">
            <h1>{edit ? 'Edit product' : 'Adding product'}</h1>

            {isUDIRequired && (
                <AdminFields isAllowed={permissions?.admin}>
                    <Input {...field('udi').register<string>()} text="UDI" required regex={/^[0-9]+$/} />
                </AdminFields>
            )}

            <AdminFields isAllowed={permissions?.create}>
                <LingualInput {...field('name').register()} text="Name" required />
            </AdminFields>

            <AdminFields isAllowed={true}>
                <LingualInput {...field('description').register()} text="Description" textarea />
            </AdminFields>

            <AdminFields isAllowed={permissions?.create}>
                <Select {...field('brand_id').register<number>()} placeholder="Brand" required>
                    {dictionaries?.brands?.map((brand) => (
                        <Select.Option key={`brand_${brand.id}`} id={brand.id}>
                            {brand.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('product_type_id').register<number>()} placeholder="Type" required>
                    {dictionaries?.product_types?.map((type) => (
                        <Select.Option key={`type_${type.id}`} id={type.id}>
                            {type.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('ages_ids').register<number[]>()} placeholder="Age" localSearch multiple>
                    {dictionaries?.ages?.map((age) => (
                        <Select.Option key={`age_${age.id}`} id={age.id}>
                            {age.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('sexes_ids').register<number[]>()} placeholder="Sex" multiple required>
                    {dictionaries?.sexes?.map((sex) => (
                        <Select.Option key={`sex_${sex.id}`} id={sex.id}>
                            {sex.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('strength_id').register<number>()} placeholder="Strength" required>
                    {dictionaries?.strengths?.map((strength) => (
                        <Select.Option key={`strength_${strength.id}`} id={strength.id}>
                            {strength.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('occasions_ids').register<number[]>()} placeholder="Occasion" multiple required>
                    {dictionaries?.occasions?.map((occasion) => (
                        <Select.Option key={`occasion_${occasion.id}`} id={occasion.id}>
                            {occasion.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('ingredients_ids').register<number[]>()} placeholder="Ingredients" localSearch multiple required>
                    {dictionaries?.ingredients?.map((ingredient) => (
                        <Select.Option key={`ingredient_${ingredient.id}`} id={ingredient.id}>
                            {ingredient.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('characters_ids').register<number[]>()} placeholder="Character" multiple required>
                    {dictionaries?.characters?.map((character) => (
                        <Select.Option key={`character_${character.id}`} id={character.id}>
                            {character.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('tags_ids').register<number[]>()} placeholder="Tags" localSearch multiple>
                    {dictionaries?.tags?.map((tag) => (
                        <Select.Option key={`tag_${tag.id}`} id={tag.id}>
                            {tag.name}
                        </Select.Option>
                    ))}
                </Select>
                <Upload.Image {...field('images_ids').register<number[]>()} value={product?.images} />
            </AdminFields>

            <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
        </div>
    );
};
