// export type FiltersProps = {
//     // type: 'Users' | 'Companies' | 'Store groups' | 'Stores' | 'Brands' | 'Currencies';
//     search: string;
//     filters?: { name: string; dictionary: string; multi?: boolean }[];
//     additionalFilters?: string[];
//     onSearchChange: (value?: string) => void;
//     onChangeFilters: (type: string, value?: number) => void;
// };

import { AllDictionary, CustomDictionary, Dictionary } from '@.types';
import { DictionaryState } from '@/Components/Hooks';

// export interface FilterOption {
//     name: string,
//     dictiona
// }

interface DictionaryFilterItem {
    name: string;
    visibleName: string;
    type: 'number[]' | 'number' | 'date' | 'datefull' | 'date&switcher' | 'string';
}

// enum DateFilter {
//     'Date',
//     'FullDate',
//     'DateAndSwitcher',
// }

export const DictionaryFilter: { [name in Dictionary | CustomDictionary]: DictionaryFilterItem } = {
    [Dictionary.Users]: {
        name: 'user_id',
        type: 'number', // <- []
        visibleName: 'User',
    },
    [Dictionary.Roles]: {
        name: 'role_id',
        type: 'number', // <- []
        visibleName: 'Role',
    },
    [Dictionary.Companies]: {
        name: 'company_id',
        type: 'number', // <- []
        visibleName: 'Company',
    },
    [Dictionary.StoreGroups]: {
        name: 'store_group_id',
        type: 'number', // <- []
        visibleName: 'Store group',
    },
    [Dictionary.Stations]: {
        name: 'station_id',
        type: 'number', // <- []
        visibleName: 'Station',
    },
    [Dictionary.Stores]: {
        name: 'store_id',
        type: 'number', // <- []
        visibleName: 'Store',
    },
    [Dictionary.Brands]: {
        name: 'brand_id',
        type: 'number', // <- []
        visibleName: 'Brand',
    },
    [Dictionary.ProductTypes]: {
        name: 'type_id',
        type: 'number', // <- []
        visibleName: 'Type',
    },
    [Dictionary.Ages]: {
        name: 'age_id',
        type: 'number[]', // <- []
        visibleName: 'Age',
    },
    [Dictionary.Sexes]: {
        name: 'sex_id',
        type: 'number[]', // <- []
        visibleName: 'Sex',
    },
    [Dictionary.Strengths]: {
        name: 'strength_id',
        type: 'number', // <- []
        visibleName: 'Strength',
    },
    [Dictionary.Occasions]: {
        name: 'occasion_id',
        type: 'number[]', // <- []
        visibleName: 'Occasion',
    },
    [Dictionary.Ingredients]: {
        name: 'ingredient_id',
        type: 'number[]', // <- []
        visibleName: 'Ingredient',
    },
    [Dictionary.Characters]: {
        name: 'character_id',
        type: 'number[]', // <- []
        visibleName: 'Character',
    },
    [Dictionary.Tags]: {
        name: 'tag_id',
        type: 'number[]', // <- []
        visibleName: 'Tag',
    },
    [Dictionary.Currencies]: {
        name: 'currency_id',
        type: 'number', // <- []
        visibleName: 'Currency',
    },
    [Dictionary.DeviceStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.ProductOrderStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.DeviceOrderStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.OrderStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.UserStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.CompanyStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.StoreGroupStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.StoreStatuses]: {
        name: 'status',
        type: 'number', // <- []
        visibleName: 'Status',
    },
    [Dictionary.StationStatuses]: {
        name: 'status',
        type: 'number',
        visibleName: 'Status',
    },
    [Dictionary.StationLogTypes]: {
        name: 'type',
        type: 'number',
        visibleName: 'Type',
    },

    [Dictionary.DeviceLogTypes]: {
        name: 'type',
        type: 'number', // <- []
        visibleName: 'Type',
    },
    [Dictionary.ManagementFileTypes]: {
        name: 'type',
        type: 'number', // <- []
        visibleName: 'Type',
    },
    [Dictionary.SpecialOfferTypes]: {
        name: 'types',
        type: 'number[]', // <- []
        visibleName: 'Type',
    },
    [Dictionary.SystemLogTypes]: {
        name: 'type',
        type: 'number', // <- []
        visibleName: 'Type',
    },
    [Dictionary.WizardSteps]: {
        name: 'step',
        type: 'number',
        visibleName: 'Step',
    },
    [Dictionary.ProductUdis]: {
        name: 'udi',
        type: 'number',
        visibleName: 'UDI',
    },
    [Dictionary.WLMFirmwareManagementFiles]: {
        name: 'firmware_management_file',
        type: 'number',
        visibleName: 'Firmware management file',
    },
    [Dictionary.LMPFirmwareManagementFiles]: {
        name: 'hardware_management_file',
        type: 'number',
        visibleName: 'Hardware management file',
    },
    [Dictionary.FavoriteHeart]: {
        name: 'favorite_heart',
        type: 'number',
        visibleName: '',
    },
    [Dictionary.ProductProposalStatuses]: {
        name: 'product_proposal_statuses',
        type: 'number',
        visibleName: 'Product proposal status',
    },
    /**
     * Custom dictionary.
     */
    [CustomDictionary.IsArchived]: {
        name: 'is_archived',
        type: 'number',
        visibleName: 'Archived',
    },
    [CustomDictionary.HasImage]: {
        name: 'has_image',
        type: 'number',
        visibleName: 'Has image',
    },
    [CustomDictionary.IsUpdated]: {
        name: 'is_updated',
        type: 'number',
        visibleName: 'Updated',
    },
    [CustomDictionary.IsEnabled]: {
        name: 'is_enabled',
        type: 'number',
        visibleName: 'Enabled',
    },
    [CustomDictionary.IsSuccess]: {
        name: 'is_successfully',
        type: 'number',
        visibleName: 'Success',
    },
};

export type Filters = (keyof typeof AllDictionary)[];

export interface FiltersProps {
    filters?: Filters;
    onChange?: (query: FilterData) => void;
    /**
     * @default true
     */
    search?: boolean;
    date?: 'date&switcher' | true | 'full';
    /**
     * null means wait for load and do not request dictionaries
     */
    fromData?: DictionaryState | null;
}

export type FilterData = Record<string, number | number[] | undefined | string> & { search?: string | undefined };
